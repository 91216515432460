:root {
  --footer-background-color: #f5f5f5;
  --text-color: #0f0f0f;
  --item-margin: 20px;
  --background-color: #ffffff;
  --card-border-radius: 10px;
  --highlight-color-light: rgba(0, 157, 255, 0.7);
  --highlight-color: rgb(0, 157, 255);
  --button-background-color: #0f0f0f;
  --disabled-color: #c8c8c8;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 15px;
}

@media (prefers-color-scheme: dark) {

  :root {
    --footer-background-color: #2d2d2d;
    --text-color: #f5f5f5;
    --item-margin: 20px;
    --background-color: #191919;
  }

}
/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
